<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_slots')" :isFilter="true" :isPrint="true"
                    @filter-div-status="filterStatus = !filterStatus" :isExpandAll="!expandAllStatus"
                    :isCollapseAll="expandAllStatus" :other-button="excelExportData" @downloadExcel="downloadExcel"
                    @expand-all="expandAll" @collapse-all="collapseAll" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_slots')" :isFilter="true"
                    @filter-div-status="filterStatus = !filterStatus" :isExpandAll="!expandAllStatus"
                    :isCollapseAll="expandAllStatus" :other-button="excelExportData" @downloadExcel="downloadExcel"
                    @expand-all="expandAll" @collapse-all="collapseAll" />
            </template>
            <div class="spinner over text-center" v-if="loading">
                <b-spinner label="Spinning" variant="primary"></b-spinner>
            </div>
            <div v-else>
                <!-- Info -->
                <div class="mb-2 d-flex">
                    <b-button variant="light"
                        class="border w-40 h-40 mr-2"
                        :class="showExtraStudentInfo ? null : 'collapsed'"
                        :aria-expanded="showExtraStudentInfo ? 'true' : 'false'"
                        aria-controls="collapse-4"
                        @click="showExtraStudentInfo = !showExtraStudentInfo"
                    >
                        <i class="ri-arrow-down-s-line" v-if="!showExtraStudentInfo"></i>
                        <i class="ri-arrow-up-s-line" v-if="showExtraStudentInfo"></i>
                    </b-button>
                    <div
                        class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                        {{ getObjectValue(student, 'student_number') }},
                        {{ getObjectValue(student, 'name') }}
                        {{ getObjectValue(student, 'surname') }}
                    </div>
                </div>
                <b-collapse id="collapse-4" v-model="showExtraStudentInfo">
                    <div class="border rounded-sm p-4 mb-4">
                        <b-row>
                            <b-col cols="auto">
                                <b-form-group :label="$t('faculty')">
                                    <div class="label-as-input">
                                        {{ student.program }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                                <b-form-group :label="$t('program')">
                                    <div class="label-as-input">
                                        {{ student.program }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                                <b-form-group :label="$t('registration_type')">
                                    <div class="label-as-input">
                                        {{ student.registration_type }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                                <b-form-group :label="$t('number_of_semesters_studied')">
                                    <div class="label-as-input">
                                        {{ student.semester_count }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="auto">
                                <b-form-group :label="$t('student_status')">
                                    <div class="label-as-input">
                                        {{ student.student_status }}
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </b-collapse>
                <b-row class="infos">
                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm text-center p-2 mb-3"
                            v-b-popover.hover.bottom="$t('cgpa') + ': ' + student.graduate_cgpa">
                            <label>{{ $t('gno') }} </label>
                            <h3 style="margin-bottom: 5px;">{{ student.cgpa ? student.cgpa : '-' }}</h3>
                        </div>
                    </b-col>
                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm mb-3">
                            <div class="text-center p-2">
                                <label class="d-block d-lg-none">{{ $t('credit') }}</label>
                                <label class="d-none d-lg-block">{{ $t('completed_credit') }}
                                </label>
                                <h3 class="mb-0">{{ student.completed_credits }} / {{ totalCredits(student.not_completed_credits,student.completed_credits) }}</h3>
                            </div>
                            <b-progress :value="student.completed_credits" :max="student.total_credits" height="5px" />
                        </div>
                    </b-col>

                    <b-col cols="6" md="3">
                        <div class="bg-white border rounded-sm mb-3">
                            <div class="text-center p-2" >
                                <label class="d-block d-lg-none">{{ $t('slot') }}</label>
                                <label class="d-none d-lg-block">{{ $t('completed_slots') }} / {{ $t('total_slots') }}
                                </label>
                                <h3 class="mb-0" style="white-space: nowrap;">{{ student.completed_slots }} / {{ student.total_slots }} </h3>
                            </div>
                            <b-progress :value="student.completed_slots" :max="student.total_slots" height="5px" />
                        </div>
                    </b-col>
                </b-row>

                <!-- Filter -->
                <div class="filter" v-show="filterStatus">
                    <h6>{{ $t('filter1') }}</h6>
                    <div class="border rounded p-4 pb-0 mb-4">
                        <b-row>
                            <b-col cols="6" md="4" lg="3">
                                <b-form-group v-slot="{ ariaDescribedby }" class="mb-1" :label="$t('slot_types')">
                                    <b-form-select v-model="filterSlotType" :options="filterSlotTypes" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6" md="4" lg="3">
                                <b-form-group v-slot="{ ariaDescribedby }" class="mb-1" :label="$t('status')">
                                    <b-form-select v-model="filterStatusType" :options="filterStatusTypes" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>

                <!-- Slots -->
                <h6>{{ $t('slots') }}</h6>
                <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered responsive
                    :items="filteredSlots" :fields="slotsFields" show-empty :tbody-tr-class="rowClass"
                    class="mb-4 table-dropdown no-scrollbar border rounded" @row-clicked="onRowClicked">
                    <template #cell(detail)="data">
                        <div class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center">
                            <i :class="data.detailsShowing ? 'ri-arrow-up-s-line font-size-20':'ri-arrow-down-s-line font-size-20'"></i>
                        </div>
                    </template>
                    <template #cell(responsive)="data">
                        <b-table-simple class="table-bordered table-striped table-sm">
                            <b-tbody>
                                <tr>
                                    <td class="font-weight-bold width-100">{{ $t('semester') }}</td>
                                    <td>{{ data.item.semester }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('slot_type') }}</td>
                                    <td>
                                        <span v-if="data.item.slot_type">
                                            {{ data.item.slot_type.name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('course_code') }}</td>
                                    <td>
                                        <span>
                                            {{ data.item.course && data.item.course.code ? data.item.course.code : '-' }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('course_name') }}</td>
                                    <td>
                                        <span v-if="data.item.slot_type && data.item.slot_type.code == 'M'">
                                            {{ data.item.course ? data.item.course.name : '-' }}
                                        </span>
                                        <span v-else-if="data.item.slot_type && data.item.slot_type.code == 'P'">
                                            {{ data.item.course ? data.item.course.name : '-' }}
                                        </span>
                                        <span v-else>
                                            {{ data.item.pool ? data.item.pool.name : '-' }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">{{ $t('credit') }}</td>
                                    <td>{{ data.item.credit }}</td>
                                </tr>

                                <tr>
                                    <td class="font-weight-bold">{{ $t('status') }}</td>
                                    <td>
                                        <span v-if="data.item.slot_status">
                                            <b-badge variant="success" v-if="data.item.slot_status == 1">
                                                {{ $t('success') }}
                                            </b-badge>
                                            <b-badge variant="primary" v-else-if="data.item.slot_status == 2">
                                                {{ $t('taking_during_active_semester') }}
                                            </b-badge>
                                            <b-badge variant="secondary" v-else-if="data.item.slot_status == 3">
                                                {{ $t('unsuccessful') }}
                                            </b-badge>
                                            <b-badge variant="warning" v-else-if="data.item.slot_status == 4">
                                                {{ $t('not_taken') }}
                                            </b-badge>
                                        </span>
                                    </td>
                                </tr>
                            </b-tbody>
                        </b-table-simple>
                    </template>
                    <template #cell(slot_type)="data">
                        <span v-if="data.item.slot_type">
                            {{ data.item.slot_type.name }}
                        </span>
                    </template>
                    <template #cell(course_code)="data">
                        <span>
                            {{ data.item.course && data.item.course.code ? data.item.course.code : '-' }}
                        </span>
                    </template>
                    <template #cell(course_name)="data">
                        <span v-if="data.item.slot_type && data.item.slot_type.code == 'M'">
                            {{ data.item.course ? data.item.course.name : '-' }}
                        </span>
                        <span v-else-if="data.item.slot_type && data.item.slot_type.code == 'P'">
                            {{ data.item.course ? data.item.course.name : '-' }}
                        </span>
                        <span v-else>
                            {{ data.item.pool ? data.item.pool.name : '-' }}
                        </span>
                    </template>
                    <template #cell(status)="data">
                        <span v-if="data.item.slot_status">
                            <b-badge variant="success" v-if="data.item.slot_status == 1">
                                {{ $t('success') }}
                            </b-badge>
                            <b-badge variant="primary" v-else-if="data.item.slot_status == 2">
                                {{ $t('taking_during_active_semester') }}
                            </b-badge>
                            <b-badge variant="secondary" v-else-if="data.item.slot_status == 3">
                                {{ $t('unsuccessful') }}
                            </b-badge>
                            <b-badge variant="warning" v-else-if="data.item.slot_status == 4">
                                {{ $t('not_taken') }}
                            </b-badge>
                        </span>
                    </template>
                    <template #row-details="data">
                        <template v-if="data.item.courses && data.item.courses.length > 0">
                            <div class="d-flex align-items-center">
                                <h6 class="ml-2 mr-3 mb-0 mt-0">{{ $t('courses') }}</h6>
                            </div>

                            <div class="bg-white m-2">
                                <b-table-simple class="table table-bordered m-0">
                                    <b-thead>
                                        <b-th class="d-table-cell d-lg-none print">{{ $t('course') }}</b-th>
                                        <b-th class="d-none d-lg-table-cell print">{{ $t('academic_year') }} / {{
                                            $t('period') }}</b-th>
                                        <b-th class="d-none d-lg-table-cell print">{{ $t('course_code') }}</b-th>
                                        <b-th class="d-none d-lg-table-cell print">{{ $t('course_name') }}</b-th>
                                        <b-th class="d-none d-lg-table-cell print text-center">
                                            {{ $t('credit') }}
                                        </b-th>
                                        <b-th class="d-none d-lg-table-cell print text-center">
                                            {{ $t('letter_grade') }}
                                        </b-th>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(course, courseKey) in data.item.courses" :key="'course' + course.id">
                                            <b-td class="d-table-cell d-lg-none print">
                                                <b-table-simple class="table-bordered table-striped table-sm">
                                                    <b-tbody>
                                                        <tr>
                                                            <td class="font-weight-bold width-100">{{
                                                                $t('academic_year') }}</td>
                                                            <td>
                                                                {{ course.semester && course.semester.academic_year ?
                                                                    course.semester.academic_year : '-' }}
                                                                /
                                                                {{ course.semester && course.semester.semester ?
                                                                    course.semester.semester : '-' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-weight-bold width-100">{{
                                                                $t('course_code') }}</td>
                                                            <td>
                                                                {{ course.course_code || '-' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-weight-bold width-100">{{
                                                                $t('course_name') }}</td>
                                                            <td>
                                                                {{ course.course_name || '-' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-weight-bold width-100">{{
                                                                $t('letter_grade') }}</td>
                                                            <td>
                                                                {{ course.letter_grade ? course.letter_grade : '-' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="font-weight-bold width-100">{{ $t('credit') }}
                                                            </td>
                                                            <td>
                                                                {{ course.credit ? course.credit : 0 }}
                                                            </td>
                                                        </tr>
                                                    </b-tbody>
                                                </b-table-simple>
                                            </b-td>
                                            <b-td class="d-none d-lg-table-cell print align-middle">
                                                {{ course.semester && course.semester.academic_year ?
                                                    course.semester.academic_year : '-' }}
                                                /
                                                {{ course.semester && course.semester.semester ?
                                                    course.semester.semester : '-' }}
                                            </b-td>
                                            <b-td class="d-none d-lg-table-cell print align-middle">
                                                {{ course.course_code || '-' }}
                                            </b-td>
                                            <b-td class="d-none d-lg-table-cell print align-middle">
                                                {{ course.course_name || '-' }}
                                            </b-td>
                                            <b-td class="d-none d-lg-table-cell print align-middle text-center">
                                                {{ course.credit }}
                                            </b-td>
                                            <b-td class="d-none d-lg-table-cell print align-middle text-center">
                                                {{ course.letter_grade ? course.letter_grade : '-' }}
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </template>
                        <div class="border rounded-sm pl-3" v-else>
                            {{ $t('no_course') }}
                        </div>
                    </template>
                </b-table>
            </div>

        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Services
import StudentProgramService from '@/services/StudentProgramService'
import StudentSlotService from '@/services/StudentSlotService'
import SlotTypeService from '@/services/SlotTypeService'

export default {
    name: "StudentProgramSlots",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t('student_slots')
        }
    },
    data() {
        return {
            // Loading
            loading: false,
            expandAllStatus: false,

            // Visible
            showExtraStudentInfo: false,

            // Student
            studentProgramId: null,
            studentProgramCode: null,
            student: {},
            studentCourseForm: null,

            // Filters
            filterStatus: false,
            filterSlotType: null,
            filterSlotTypes: [],
            filterStatusType: null,
            filterStatusTypes: [
                { value: null, text: this.$t('all') },
                { value: 1, text: this.$t('success') },
                { value: 2, text: this.$t('taking_during_active_semester') },
                { value: 3, text: this.$t('unsuccessful') },
                { value: 4, text: this.$t('not_taken') }
            ],

            // Slots
            slots: [],
            slotsFields: [
                {
                    key: 'responsive',
                    label: this.toUpperCase('slot'),
                    thClass: 'd-table-cell d-lg-none print',
                    tdClass: 'd-table-cell d-lg-none print'
                },
                {
                    key: 'semester',
                    label: this.toUpperCase('semester'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print align-middle text-center'
                },
                {
                    key: 'program_module_name',
                    label: this.toUpperCase('module_name'),
                    thClass: 'd-none d-lg-table-cell text-center',
                    tdClass: 'd-none d-lg-table-cell align-middle text-center'
                },
                {
                    key: 'slot_type',
                    label: this.toUpperCase('course_type'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'course_code',
                    label: this.toUpperCase('course_code'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'course_name',
                    label: this.toUpperCase('course_name'),
                    thClass: 'd-none d-lg-table-cell print',
                    tdClass: 'd-none d-lg-table-cell print align-middle'
                },
                {
                    key: 'credit',
                    label: this.toUpperCase('credit'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print text-center align-middle'
                },

                {
                    key: 'status',
                    label: this.toUpperCase('status'),
                    thClass: 'd-none d-lg-table-cell print text-center',
                    tdClass: 'd-none d-lg-table-cell print text-center'
                },
                {
                    key: 'detail',
                    label: ' ',
                    class: this.moveMode ? 'width-150 p-1 align-middle' : 'w-40 p-1 align-middle'
                }
            ],
            slotId: null,

            // Select & Detail
            slotsSelectIndex: null,
            slotsDetailsRow: null,
            excelExportData: {
                title: 'EXCEL',
                show: true,
                icon: 'ri-download-2-line',
                emit: 'downloadExcel'
            },
            slotTypeColor: {},
        }
    },
    created() {
        SlotTypeService.getAll().then(res => res.data.data.forEach((a, index) => this.slotTypeColor[a.code] = index))
        this.studentProgramId = parseInt(this.$route.params.id)
        this.$store.dispatch("auth/initUser");
        let user = this.$store.getters['auth/getUser'];
        if (user.person.type == 'student') {
            this.studentProgramId = user.active_student_program.id
        }

        // Slots
        if (this.studentProgramId) {
            this.getSlots()
        }
        else {
            this.$router.push('/404');
        }

    },
    computed: {
        filteredSlots() {
            if (this.slots) {
                if (this.filterSlotType && this.filterStatusType) {
                    return this.slots.filter(item => {
                        return (item.slot_type.code.toLowerCase().indexOf(this.filterSlotType.toLowerCase()) > -1) && (item.slot_status == this.filterStatusType)
                    })
                }
                if (this.filterSlotType) {
                    return this.slots.filter(item => {
                        return item.slot_type.code.toLowerCase().indexOf(this.filterSlotType.toLowerCase()) > -1
                    })
                }
                if (this.filterStatusType) {
                    return this.slots.filter(item => {
                        return item.slot_status == this.filterStatusType
                    })
                }

                return this.slots;
            }
            return [];
        },


    },
    methods: {
        totalCredits(value1,value2){
            return value1 + value2
        },
        rowClass(item, type) {
            if (!item || type !== 'row') { return }
            if (item.slot_type.code in this.slotTypeColor) { return `table-slot-bg--${this.slotTypeColor[item.slot_type.code]}` }
            this.slotTypeColor[item.slot_type.code] = this.colorGenerate()
            return `table-slot-bg--${item.slot_type.code}`
        },
        colorGenerate() {
            const int = Math.floor(Math.random() * 8);
            return int
        },
        onRowClicked(item, index, event) {
            this.$set(item, '_showDetails', !item._showDetails);
            this.slotsDetailsRow = item._showDetails ? item : false;
            this.slotsSelectIndex = item._showDetails ? index : null;
        },
        expandAll() {
            this.expandAllStatus = true
            for (const item of this.slots) {
                this.$set(item, '_showDetails', true)
            }
        },
        collapseAll() {
            this.expandAllStatus = false
            for (const item of this.slots) {
                this.$set(item, '_showDetails', false)
            }
        },

        async getSlots() {
            this.loading = true
            await StudentProgramService.studentSlots(this.studentProgramId)
                .then((response) => {
                    this.student = response.data.data.student
                    this.studentProgramCode = this.student.program_code
                    this.slots = response.data.data.slots
                    this.filterSlotTypes = [
                        { value: null, text: this.$t('all') }
                    ]
                    Object.values(response.data.data.slot_types).forEach(item => {
                        this.filterSlotTypes.push({
                            value: item.code,
                            text: item.name
                        })
                    })
                    this.expandAll()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        downloadExcel() {
            StudentSlotService.downloadAsExcel(this.studentProgramId)
                .then(res => this._downloadFile(res, this.$t('student_slots') + '.xlsx'))
                .catch(err => this.showErrors(err))
        }
    },
}
</script>
<style lang="scss">
.infos .progress {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.b-table-details {
    background: #f1f1f1 !important;
}

$tableColors: (
    0: #7D8CA1,
    1: #7E9DCC,
    2: #4B8CCC,
    3: #4BA7CC,
    4: #4BB9CC,
    5: #4BCCC2,
    6: #4BCCA4,
    7: #4BCC83,
);

//@each $colorname, $color in $tableColors {
//    .table-slot-bg--#{$colorname} {
//        background-color: rgba($color, $alpha: 0.3);
//    }
//}
</style>
